import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  handleSubmit(e) {
    const buttons = e.target.querySelectorAll("button[type=submit]");
    const submits = e.target.querySelectorAll("[type=submit]");

    for (const btn of buttons) {
      btn.disabled = true;
      btn.innerText = "Submitting...";
    }

    for (const btn of submits) {
      btn.disabled = true;
      btn.value = "Submitting...";
    }
  }
}
