import $ from "jquery";

(function () {
  if (typeof window.CustomEvent === "function") return false;

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

export const growl = (detail) => {
  const event = new CustomEvent("growl", { detail });
  window.dispatchEvent(event);
};

export const toQueryString = (obj) => {
  let str = "";
  for (var key in obj) {
    if (str != "") {
      str += "&";
    }
    str += key + "=" + encodeURIComponent(obj[key]);
  }
  return str;
};

export const formatPrice = (amount, currency = "USD") => {
  return amount.toLocaleString("en-US", { style: "currency", currency });
};

export const htmlToElement = (html) => {
  var template = document.createElement("template");
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
};

export const delegate = (eventName, { parameters }) => {
  const handler = (...args) => {
    const data = {};
    for (let index = 0; index < parameters.length; index++) {
      const name = parameters[index];
      data[name] = args[index];
    }
    const delegatedEvent = new CustomEvent(`jquery:${eventName}`, {
      bubbles: true,
      cancelable: true,
      detail: data,
    });
    return data.event.target.dispatchEvent(delegatedEvent);
  };
  return $(document).on(eventName, handler);
};
