import $ from "jquery";
import { Core, XHRUpload, Dashboard } from "uppy";
import { Controller } from "@hotwired/stimulus";
import { htmlToElement } from "../utils";

export default class extends Controller {
  static targets = [
    "addBeneficiaryButton",
    "apiInstructions",
    "beneficiary",
    "beneficiaryForm",
    "beneficiaryInput",
    "beneficiariesWrapper",
    "browserInstructions",
    "errorHelp",
    "form",
    "genericDepositFields",
    "addressForm",
    "numContainersInput",
    "productDescriptionInput",
    "segregatedDepositFields",
    "submit",
    "uploadArea"
  ];

  get agreement() {
    return this.data.get("agreement");
  }

  set container(val) {
    this.data.set("container", val);
  }

  get mup() {
    return this.data.get("mup") == "true";
  }
  
  get depositType() {
    return this.data.get("depositType");
  }
  
  set depositType(val) {
    this.data.set("depositType", val)
  }

  toggleSegregatedDeposit(e) {
    if (e.target.value == "1") {
      this.genericDepositFieldsTarget.classList.add("d-none");
      this.segregatedDepositFieldsTarget.classList.remove("d-none");
    } else {
      this.segregatedDepositFieldsTarget.classList.add("d-none");
      this.genericDepositFieldsTarget.classList.remove("d-none");
    }
  }
  
  setDepositType(e) {
    this.depositType = e.target.value;
    
    if (this.depositType === "api") {
      this.uploadAreaTarget.classList.add("d-none");
      this.submitTarget.value = "Create Deposit Container";
      this.submitTarget.disabled = false;
      this.browserInstructionsTarget.classList.add("d-none");
      this.apiInstructionsTarget.classList.remove("d-none");
    } else {
      this.uploadAreaTarget.classList.remove("d-none");
      this.submitTarget.value = "Upload Deposit";
      this.submitTarget.disabled = true;
      this.browserInstructionsTarget.classList.remove("d-none");
      this.apiInstructionsTarget.classList.add("d-none");
    }
  }

  connect() {
    if (!document.getElementById("drag-drop-area")) {
      return;
    }
    this.uppy = Core({
      restrictions: {
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        maxFileSize: 50000000000,
        allowedFileTypes: [".zip"],
      },
    })
      .use(Dashboard, {
        inline: true,
        target: "#drag-drop-area",
        proudlyDisplayPoweredByUppy: false,
        hideUploadButton: true,
        height: 250,
      })
      .use(XHRUpload, {
        endpoint: `/artifacts/upload`,
        timeout: 0,
      });

    this.uppy.on("upload-success", (file, response) => {
      const artifactId = document.getElementById("artifact_id");
      artifactId.value = response.body.artifact_id;
    });

    this.uppy.on("complete", (result) => {
      if (result.failed.length == 0) {
        this.formTarget.submit();
      }
    });

    this.uppy.on("file-added", (file) => {
      if (this.uppy.getFiles().length > 0) {
        this.submitTarget.disabled = false;
      }
    });

    this.uppy.on("upload-error", (error) => {
      this.errorHelpTarget.classList.remove("d-none");
      console.error(error);
    });
  }

  toggleNumContainerReadOnly(e) {
    if (e.target.value == "0") {
      this.numContainersInputTarget.readOnly = false;
    } else {
      this.numContainersInputTarget.readOnly = true;
      this.numContainersInputTarget.value = "";
    }
  }

  addProductRow(e) {
    e.preventDefault();

    const html = htmlToElement(`
      <div class="form-row mb-3">
        <div class="col">
          <input type="text" name="product_description-${this.productDescriptionInputTarget.children.length}-description" class="form-control">
        </div>
        <div class="col">
          <input type="text" name="product_description-${this.productDescriptionInputTarget.children.length}-release_number" class="form-control">
        </div>
      </div>
    `);

    this.productDescriptionInputTarget.appendChild(html);
  }

  toggleAddressForm(e) {
    if (e.target.checked) {
      this.addressFormTarget.classList.add("d-none");
    } else {
      this.addressFormTarget.classList.remove("d-none");
    }
  }

  async setBeneficiary(e) {
    if (this.mup) {
      const el = e.target;
      const beneficiaryId = el.options[el.selectedIndex].value;

      if (beneficiaryId == "__None") {
        return;
      }

      const url = `/api/accounts/${beneficiaryId}`;
      const response = await fetch(url);

      if (!response.ok) {
        console.log(await response.text());
        return false;
      }

      const json = await response.json();

      if (!json.valid_email) {
        alert(
          "This beneficiary does not have a valid email address on file. Please update the Primary Contact before continuing with the upload."
        );
        el.value = "__None";
      }
      this.beneficiaryInputTarget.value = json.primary_contact.email;
    }
  }

  async handleSubmit(e) { 
    if (this.depositType === "browser") {
      e.preventDefault();
      this.submitTarget.disabled = true;
      this.uppy.upload(); 
    }
  }
}
