import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  async validateBeneficiary(e) {
    const valid = await this.findBeneficiary(e.target.value);

    if (valid) {
      e.target.classList.remove("is-invalid");
      e.target.classList.add("is-valid");
    } else {
      e.target.classList.remove("is-valid");
      e.target.classList.add("is-invalid");
    }
  }

  async findBeneficiary(query) {
    if (!query) {
      return false;
    }

    const url = `${this.data.get("url")}?name=${encodeURIComponent(query)}`;
    const response = await fetch(url);

    if (!response.ok) {
      console.log(await response.text());
      return false;
    }

    const json = await response.json();
    return json.length > 0;
  }
}
