import { Controller } from "@hotwired/stimulus";
import { htmlToElement } from "../utils";

export default class extends Controller {
  static targets = ["recipientInput"];

  addRecipient(e) {
    e.preventDefault();

    const len = this.recipientInputTarget.children.length;
    const html = htmlToElement(`
      <div class="form-row mb-3">
        <div class="col">
          <input type="text" class="form-control" name="recipients-${len}-company" id="recipients-${len}-company" placeholder="Company Name">
        </div>
        <div class="col">
          <input type="text" class="form-control" name="recipients-${len}-co_name" id="recipients-${len}-co_name" placeholder="Recipient Name">
        </div>
        <div class="col">
          <input type="email" class="form-control" name="recipients-${len}-email" id="recipients-${len}-email" placeholder="Enter the recipient's email">
        </div>
      </div>
      <div class="form-group">
        <input type="text" class="form-control" name="beneficiary-${len}" value="" placeholder="Enter beneficiary ID">
      </div>
    `);

    this.recipientInputTarget.appendChild(html);
  }
}
