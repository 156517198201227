import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "beneficiaryField",
    "form",
    "otherRecipientForm",
    "recipientRole",
    "submit",
  ];

  connect() {
    if (this.data.get("disabled").toLowerCase() == "true") {
      this.submitTarget.disabled = true;
    }
  }

  selectReportType(e) {
    e.preventDefault();
    const inputs = document.querySelectorAll("[name=report_type]");

    let totalChecked = 0;

    inputs.forEach((i) => {
      if (i.checked) {
        totalChecked += 1;
      }
    });

    if (totalChecked < 1) {
      this.submitTarget.disabled = true;
    } else {
      this.submitTarget.disabled = false;
    }
  }

  getRecipientRole() {
    const inputs = document.querySelectorAll("[name=role]");

    for (const el of inputs) {
      if (el.checked) {
        return el.value;
      }
    }
  }

  toggleOtherFormFields(e) {
    const recipient = this.getRecipientRole();

    if (recipient == "beneficiary") {
      this.beneficiaryFieldTarget.classList.remove("d-none");
    } else {
      this.beneficiaryFieldTarget.classList.add("d-none");
    }

    if (recipient == "other") {
      this.otherRecipientFormTarget.classList.remove("d-none");
    } else {
      this.otherRecipientFormTarget.classList.add("d-none");
    }
  }

  toggleAllBeneficiaries(e) {
    if (e.target.checked) {
      this.beneficiaryFieldTarget.classList.add("d-none");
    } else {
      this.beneficiaryFieldTarget.classList.remove("d-none");
    }
  }
}
