import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  print(e) {
    e.preventDefault();
    const printWindow = window.open(
      this.data.get("pdfPath"),
      "",
      "left=100,top=100,width=600,height=600"
    );
    printWindow.onload = () => {
      printWindow.focus();
      printWindow.setTimeout(printWindow.print, 500);
    };
  }
}
