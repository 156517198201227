import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["signature"];

  connect() {
    this.loadSignature();
  }

  updateSignature(signature) {
    this.signatureTarget.classList.remove("loader");
    this.signatureTarget.classList.add("tag");
    this.signatureTarget.innerHTML = signature;
  }

  async loadSignature() {
    const response = await fetch(this.data.get("url"));

    if (!response.ok) {
      console.log(await response.text());
      return false;
    }

    const json = await response.json();
    if (json.artifact.signature != "") {
      this.updateSignature(json.artifact.signature);
      return true;
    } else {
      setTimeout(() => {
        this.loadSignature();
      }, 2000);
    }
  }
}
