import { Controller } from "@hotwired/stimulus";
import { htmlToElement } from "../utils";

export default class extends Controller {
  static targets = [
    "allBeneficiaries",
    "beneficiaryInputs",
    "beneficiariesWrapper",
  ];

  toggleSegregatedDeposit(e) {
    if (e.target.value == "1") {
      this.beneficiariesWrapperTarget.classList.remove("d-none");
    } else {
      this.beneficiariesWrapperTarget.classList.add("d-none");
    }

    if (this.hasAllBeneficiariesTarget) {
      if (e.target.value == "1") {
        this.allBeneficiariesTarget.classList.remove("d-none");
      } else {
        this.allBeneficiariesTarget.classList.add("d-none");
      }
    }
  }

  toggleAllBeneficiaries(e) {
    if (!e.target.checked) {
      this.beneficiariesWrapperTarget.classList.remove("d-none");
    } else {
      this.beneficiariesWrapperTarget.classList.add("d-none");
    }
  }
}
