import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "actionLabel",
    "currentAction",
    "forceDestroy",
    "rationaleField",
    "returnNoticeText",
    "submissionIdField",
    "submit",
  ];

  get action() {
    return this.currentActionTarget.value;
  }

  set action(value) {
    this.currentActionTarget.value = value;
  }

  get forceDestroy() {
    return this.forceDestroyTarget.value == "true";
  }

  set forceDestroy(value) {
    this.forceDestroyTarget.value = value;
  }

  connect() {
    if (this.data.get("disabled").toLowerCase() == "true") {
      this.submitTarget.disabled = true;
    }
  }

  selectAction(e) {
    e.preventDefault();
    const inputs = document.querySelectorAll("[name=action]");

    let totalChecked = 0;

    inputs.forEach((i) => {
      if (i.checked) {
        i.checked = false;
      }
    });

    e.target.checked = true;
    this.action = e.target.value;
    this.updateForm();
    this.updateLabels();

    this.forceDestroy = this.action != "return";
  }

  updateForm() {
    if (this.action == "return") {
      this.rationaleFieldTarget.classList.add("d-none");
      this.rationaleFieldTarget.querySelector("textarea").required = false;

      this.submissionIdFieldTarget.classList.remove("d-none");
      this.submissionIdFieldTarget.querySelector("input").required = true;

      this.returnNoticeTextTarget.classList.remove("d-none");
    } else {
      this.rationaleFieldTarget.classList.remove("d-none");
      this.rationaleFieldTarget.querySelector("textarea").required = true;

      this.submissionIdFieldTarget.classList.add("d-none");
      this.submissionIdFieldTarget.querySelector("input").required = false;

      this.returnNoticeTextTarget.classList.add("d-none");
    }
  }

  updateLabels() {
    const actionLabel = this.action.replace(/^\w/, (c) => c.toUpperCase());
    this.actionLabelTargets.forEach((l) => {
      l.textContent = actionLabel;
    });

    this.submitTarget.textContent = `${actionLabel} Container`;
  }
}
