import { Controller } from "@hotwired/stimulus";
import { htmlToElement } from "../utils";

const getOptionValue = (el, label) => {
  for (const opt of el) {
    if (opt.innerHTML == label) {
      return opt.value;
    }
  }
  return false;
};

const getOptionName = (el, value) => {
  for (const opt of el) {
    if (opt.value == value) {
      return opt.innerHTML;
    }
  }
  return false;
};

export default class extends Controller {
  static targets = ["dateSentInput", "status", "location"];

  setStatus(e) {
    if (e.target.value == "__None") {
      return;
    }
    const status = getOptionValue(this.statusTarget, "Permanent");
    this.statusTarget.value = status;
  }

  setLocation(e) {
    const current = getOptionName(this.statusTarget, e.target.value);
    if (current == "Returned" || current == "Destroyed") {
      const loc = getOptionValue(this.locationTarget, "__None");
      this.locationTarget.value = loc;
    }
  }

  validateDateReceived(e) {
    const dateReceived =
      e.target.value !== undefined ? Date.parse(e.target.value) : null;
    const dateSent =
      this.dateSentInputTarget.value !== undefined
        ? Date.parse(this.dateSentInputTarget.value)
        : null;

    if (dateReceived !== null && dateSent !== null) {
      if (dateReceived <= dateSent) {
        e.target.classList.add("is-invalid");
      } else {
        e.target.classList.remove("is-invalid");
      }
    }
  }
}
