import $ from "jquery";
import { Controller } from "@hotwired/stimulus";

import { toQueryString } from "../utils.js";

export default class extends Controller {
  static targets = ["select"];

  get url() {
    return this.data.get("url");
  }

  get valueField() {
    return this.data.get("valueField");
  }

  get labelField() {
    return this.data.get("labelField");
  }

  get searchField() {
    return this.data.get("searchField");
  }

  get maxItems() {
    return parseInt(this.data.get("maxItems")) || 1;
  }

  get delimiter() {
    return this.data.get("delimiter");
  }

  get create() {
    const create = this.data.get("create");
    if (create != null) {
      return create === "true";
    }
    return null;
  }

  connect() {
    this.selectizeMount();

    document.addEventListener(
      "turbolinks:before-cache",
      () => {
        this.selectizeUnmount();
      },
      { once: true }
    );
  }

  selectizeMount() {
    let options = {};

    if (this.maxItems != null) {
      options = {
        ...options,
        maxItems: this.maxItems,
      };
    }

    if (this.create != null) {
      options = {
        ...options,
        create: this.create,
      };
    }

    if (this.url != null) {
      const baseUrl = this.url;
      const valueField = this.valueField;
      const labelField = this.labelField;
      const searchField = this.searchField;

      options = {
        ...options,
        valueField: valueField,
        labelField: labelField,
        searchField: searchField,
        render: {
          item: function (item, escape) {
            const json = JSON.stringify(item);
            return (
              "<div data-json='" +
              json +
              "'\">" +
              (item[labelField]
                ? '<span class="name">' + escape(item[labelField]) + "</span>"
                : "")
            );
            ("</div>");
          },
          option: function (item, escape) {
            return `<div>${escape(item[labelField])}</div>`;
          },
        },
        load: async function (query, callback) {
          if (!query.length) return callback();
          const url = `${baseUrl}?q=${encodeURIComponent(query)}`;
          const response = await fetch(url);

          if (!response.ok) {
            console.log(await response.text());
            callback();
          }
          const json = await response.json();
          callback(json);
        },
      };
    }

    $(this.selectTarget).selectize(options);
  }

  selectizeUnmount() {
    this.saveState();
    $(this.selectTarget).selectize.destroy();
  }

  saveState() {
    let values = $(this.selectTarget).val();

    // make sure the HTML itself has those elements selected, since the HTML is what is saved in the turbolinks snapshot
    values.forEach((val) => {
      $(this.selectTarget)
        .find(`option[value="${val}"]`)
        .attr("selected", "selected");
    });
  }
}
