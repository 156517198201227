import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "agreementStatusFilter",
    "storageLocationFilter",
    "terminationYearFilter",
  ];

  updateFilters(e) {
    const el = e.target;
    const format = el.options[el.selectedIndex].value;

    if (format == "agreements" || format == "containers") {
      this.agreementStatusFilterTarget.classList.remove("d-none");
      this.storageLocationFilterTarget.classList.add("d-none");
      this.terminationYearFilterTarget.classList.add("d-none");
    } else if (format == "inventory") {
      this.agreementStatusFilterTarget.classList.add("d-none");
      this.storageLocationFilterTarget.classList.remove("d-none");
      this.terminationYearFilterTarget.classList.add("d-none");
    } else if (format == "agreements_in_holding") {
      this.agreementStatusFilterTarget.classList.add("d-none");
      this.storageLocationFilterTarget.classList.add("d-none");
      this.terminationYearFilterTarget.classList.remove("d-none");
    } else {
      this.agreementStatusFilterTarget.classList.add("d-none");
      this.storageLocationFilterTarget.classList.add("d-none");
      this.terminationYearFilterTarget.classList.add("d-none");
    }
  }
}
