import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit"];

  get url() {
    return this.data.get("url");
  }

  async findBeneficiary(query) {
    const url = `${this.url}?name=${encodeURIComponent(query)}`;
    const response = await fetch(url);

    if (!response.ok) {
      console.log(await response.text());
      return false;
    }

    const json = await response.json();
    return json.length > 0;
  }

  async validateBeneficiary(e) {
    const val = e.target.value;

    if (await this.findBeneficiary(val)) {
      e.target.classList.remove("is-invalid");
      e.target.classList.add("is-valid");
      this.submitTarget.disabled = false;
    } else {
      e.target.classList.remove("is-valid");
      e.target.classList.add("is-invalid");
      this.submitTarget.disabled = true;
    }
  }
}
