import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "submit"];

  validate() {
    if (this.inputTarget.value == this.data.get("verification")) {
      this.inputTarget.classList.remove("is-invalid");
      this.inputTarget.classList.add("is-valid");
      this.submitTarget.disabled = false;
    } else {
      this.inputTarget.classList.remove("is-valid");
      this.inputTarget.classList.add("is-invalid");
      this.submitTarget.disabled = true;
    }
  }
}
