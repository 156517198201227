import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["growl"];

  handleGrowl(e) {
    const { message, category } = e.detail;
    this.push(message, category);
  }

  push(message, category) {
    const clone = document.importNode(
      document.querySelector("#growlTemplate").content,
      true
    );
    clone.querySelector(".alert-message").textContent = message;
    if (category != "primary") {
      const notification = clone.querySelector(".alert");
      notification.classList.remove("alert-primary");
      notification.classList.add(`alert-${category}`);
    }
    this.growlTarget.append(clone);
  }
}
