import "@babel/polyfill";
import "@stimulus/polyfills";
import $ from "jquery";
import selectize from "selectize";
import bsCustomFileInput from "bs-custom-file-input";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

import "bootstrap";
import validate from "validate.js";
import { delegate } from "./utils";
import "./scss/app.scss";
import Flatpickr from "stimulus-flatpickr";

window.Stimulus = Application.start();
const context = require.context("./controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

Stimulus.register("flatpickr", Flatpickr);

$(document).ready(function () {
  bsCustomFileInput.init();

  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });
});

// Bootstrap (v4.1)
//
// Source: https://getbootstrap.com/docs/4.1/getting-started/javascript/#events
//
// ~ alert
delegate("close.bs.alert", { parameters: ["event"] });
delegate("closed.bs.alert", { parameters: ["event"] });
// ~ carousel
delegate("slide.bs.carousel", { parameters: ["event"] });
delegate("slid.bs.carousel", { parameters: ["event"] });
// ~ collapse
delegate("show.bs.collapse", { parameters: ["event"] });
delegate("shown.bs.collapse", { parameters: ["event"] });
delegate("hide.bs.collapse", { parameters: ["event"] });
delegate("hidden.bs.collapse", { parameters: ["event"] });
// ~ dropdown
delegate("show.bs.dropdown", { parameters: ["event"] });
delegate("shown.bs.dropdown", { parameters: ["event"] });
delegate("hide.bs.dropdown", { parameters: ["event"] });
delegate("hidden.bs.dropdown", { parameters: ["event"] });
// ~ tab
delegate("show.bs.tab", { parameters: ["event"] });
delegate("shown.bs.tab", { parameters: ["event"] });
delegate("hide.bs.tab", { parameters: ["event"] });
delegate("hidden.bs.tab", { parameters: ["event"] });
// ~ modal
delegate("show.bs.modal", { parameters: ["event"] });
delegate("shown.bs.modal", { parameters: ["event"] });
delegate("hide.bs.modal", { parameters: ["event"] });
delegate("hidden.bs.modal", { parameters: ["event"] });
// ~ popover
delegate("show.bs.popover", { parameters: ["event"] });
delegate("shown.bs.popover", { parameters: ["event"] });
delegate("hide.bs.popover", { parameters: ["event"] });
delegate("hidden.bs.popover", { parameters: ["event"] });
delegate("inserted.bs.popover", { parameters: ["event"] });
// ~ scrollspy
delegate("activate.bs.scrollspy", { parameters: ["event"] });
// ~ tooltip
delegate("show.bs.tooltip", { parameters: ["event"] });
delegate("shown.bs.tooltip", { parameters: ["event"] });
delegate("hide.bs.tooltip", { parameters: ["event"] });
delegate("hidden.bs.tooltip", { parameters: ["event"] });
delegate("inserted.bs.tooltip", { parameters: ["event"] });
