import { Controller } from "@hotwired/stimulus";

const fieldNames = {
  "primary_contact-name": "billing_name",
  "primary_contact-email": "billing_email",
  "primary_contact-phone_number": "billing_phone_number",
  "primary_contact-fax_number": "billing_fax_number",
  "address-address_1": "billing_address-address_1",
  "address-address_2": "billing_address-address_2",
  "address-city": "billing_address-city",
  "address-region": "billing_address-region",
  "address-postal_code": "billing_address-postal_code",
  "address-country": "billing_address-country",
};

export default class extends Controller {
  static targets = [
    "agreementType",
    "billingParty",
    "licenseeContact",
    "licenseeBilling",
    "licenseeBillingSame",
    "licensorBilling",
    "licensorBillingSame",
  ];

  get mup() {
    return this.data.get("mup") == "true";
  }

  getBillingPartyValue() {
    let party;
    this.billingPartyTargets.forEach((t) => {
      if (t.checked) {
        party = t.value;
      }
    });
    return party;
  }

  updateDisplay() {
    const billingParty = this.getBillingPartyValue();

    if (!this.mup) {
      if (billingParty == "licensor") {
        this.licensorBillingTarget.classList.remove("d-none");
        this.licenseeBillingTarget.classList.add("d-none");
      }

      if (!this.mup && billingParty == "beneficiary") {
        this.licensorBillingTarget.classList.add("d-none");
        this.licenseeBillingTarget.classList.remove("d-none");
      }
    }
  }

  copyPrimaryLicensorContact(e) {
    if (!e.target.checked) {
      return;
    }
    for (const [key, val] of Object.entries(fieldNames)) {
      const source = document.querySelector(`[name="licensor-${key}"]`);
      const dest = document.querySelector(`[name="licensor-${val}"]`);

      if (source != undefined && dest != undefined) {
        dest.value = source.value;
      }
    }
  }

  copyPrimaryLicenseeContact(e) {
    if (!e.target.checked) {
      return;
    }
    for (const [key, val] of Object.entries(fieldNames)) {
      const source = document.querySelector(`[name="beneficiary-${key}"]`);
      const dest = document.querySelector(`[name="beneficiary-${val}"]`);

      if (source != undefined && dest != undefined) {
        dest.value = source.value;
      }
    }
  }
}
