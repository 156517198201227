import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "addressForm",
    "addressRequiredInput",
    "form",
    "newContainer",
    "newContainerFeedback",
    "newContainerInput",
    "newContainerWrapper",
    "noReplacementRequiredText",
    "oldContainer",
    "oldContainerFeedback",
    "oldContainerInput",
    "replacementRequiredText",
    "returnLabel",
    "submissionId",
  ];

  get agreement() {
    return this.data.get("agreement");
  }

  get physical() {
    return this.data.get("physical") === "true";
  }

  set physical(val) {
    this.data.set("physical", val);
    this.updateAddressRequired();
  }

  get destroy() {
    return this.data.get("destroy") === "true";
  }

  set destroy(val) {
    this.data.set("destroy", val);
    this.updateAddressRequired();
  }

  get addressRequired() {
    return this.data.get("addressRequired") === "true";
  }

  set addressRequired(val) {
    this.data.set("addressRequired", val);
    this.addressRequiredInputTarget.value = val;
  }

  updateAddressRequired() {
    if (!this.physical || this.destroy) {
      this.addressRequired = false;
    } else {
      this.addressRequired = true;
    }
  }

  maskContainer(e) {
    let val = e.target.value.replace(this.agreement, "");
    e.target.value = `${this.agreement}${val}`;
  }

  updateContainers() {
    this.oldContainerTargets.forEach((e) => {
      e.textContent = this.oldContainerInputTarget.value;
    });
    this.newContainerTargets.forEach((e) => {
      e.textContent = this.newContainerInputTarget.value;
    });
  }

  async validateContainers(e) {
    const newContainerVal = this.newContainerInputTarget.value;
    const oldContainerVal = this.oldContainerInputTarget.value;
    const formSubmit = document.getElementById("formSubmit");

    const formData = new FormData();

    formData.append("old_container", oldContainerVal);
    formData.append("new_container", newContainerVal);

    const url = this.data.get("url");
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      console.log(await response.text());
      return false;
    }

    const json = await response.json();
    const placeholder = `${this.agreement}.`;
    const newContainerDirty = this.newContainerInputTarget.value != placeholder;

    if (!json.replacement_required) {
      this.newContainerInputTarget.readOnly = true;
      this.newContainerWrapperTarget.classList.add("d-none");

      this.noReplacementRequiredTextTarget.classList.remove("d-none");
      this.replacementRequiredTextTarget.classList.add("d-none");

      this.submissionIdTargets.forEach((e) => {
        e.textContent = json.submission_id;
      });
    } else {
      this.newContainerInputTarget.readOnly = false;
      this.newContainerWrapperTarget.classList.remove("d-none");

      this.noReplacementRequiredTextTarget.classList.add("d-none");
      this.replacementRequiredTextTarget.classList.remove("d-none");

      if (newContainerDirty && !json.new_container.valid) {
        this.newContainerInputTarget.classList.remove("is-valid");
        this.newContainerInputTarget.classList.add("is-invalid");
        this.newContainerFeedbackTarget.textContent =
          json.new_container.message;
        this.newContainerFeedbackTarget.classList.add("d-block");
        formSubmit.disabled = true;
      } else if (json.new_container.valid) {
        this.newContainerInputTarget.classList.remove("is-invalid");
        this.newContainerInputTarget.classList.add("is-valid");
        this.newContainerFeedbackTarget.classList.remove("d-block");
        formSubmit.disabled = true;
      }
    }

    if (!json.old_container.valid) {
      this.oldContainerInputTarget.classList.remove("is-valid");
      this.oldContainerInputTarget.classList.add("is-invalid");
      this.oldContainerFeedbackTarget.textContent = json.old_container.message;
      this.oldContainerFeedbackTarget.classList.add("d-block");
      formSubmit.disabled = true;
    } else {
      this.oldContainerInputTarget.classList.remove("is-invalid");
      this.oldContainerInputTarget.classList.add("is-valid");
      this.oldContainerFeedbackTarget.classList.remove("d-block");
      formSubmit.disabled = true;
    }

    if (
      (json.new_container.valid && json.old_container.valid) ||
      (!json.replacement_required && json.old_container.valid)
    ) {
      this.updateContainers();
      this.physical = json.old_container.physical;

      if (!this.physical) {
        this.addressFormTarget.classList.add("d-none");
        this.returnLabelTarget.textContent = `You are hereby directed to return the materials above described to email address ${this.data.get(
          "email"
        )}.`;
      } else {
        this.returnLabelTarget.textContent = `You are hereby directed to return the materials above described.`;
      }

      formSubmit.disabled = false;
    }
  }

  toggleAddressForm(e) {
    this.destroy = e.target.value == "destroy";

    if (this.addressRequired) {
      this.addressFormTarget.classList.remove("d-none");
    } else {
      this.addressFormTarget.classList.add("d-none");
    }
  }
}
