import QRCode from 'qrcode';
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "qrCodeCanvas",
    "qrCodeWrapper"
  ]
  
  get provisioningUrl() {
    return this.data.get("provisioningUrl");
  }
  
  connect() {
    QRCode.toCanvas(this.qrCodeCanvasTarget, this.provisioningUrl)
  }
  
  toggleQRCode(e) {
    if (e.target.value == "totp") {
      this.qrCodeWrapperTarget.classList.remove("d-none")
    }   else {
      this.qrCodeWrapperTarget.classList.add("d-none")
    }
  }
}
